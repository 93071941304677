//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'
import { isWeekend } from '~/utils/date';
import { processScssVars } from '~/utils/misc';
import scssVars from '~/assets/scss/variables/todo_list_timeline.scss';
import _ from 'lodash';

const { dayColumnWidth } = processScssVars(scssVars);

export default {
  layout: 'index',
  data() {
    return {
      timelineStartDate: dayjs(),
      timelineNumDays: 0
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  mounted() {
    this.updateTimeline();
    window.addEventListener('resize', this.windowResizeFunc = _.debounce(this.onWindowResize.bind(this), 500));

    switch (this.$route.query.action) {
      case 'login':
        this.$nuxt.$emit('show-log-in-dialog');
        break;

      case 'confirmed-email':
        if (!this.isLoggedIn) {
          this.$nuxt.$emit('show-log-in-dialog');
        } else {
          this.$router.replace({ name: 'projects' });
        }
        break;

      case 'change-password': {
        const token = this.$route.query.token;
        const email = this.$route.query.email;
        if (token && email) {
          this.$nuxt.$emit('show-change-password-dialog', { token, email });
        }
        break;
      }

      default:
        if (this.isLoggedIn) {
          this.$router.replace({ name: 'projects' });
        }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResizeFunc);
  },
  methods: {
    showSignUpDialog() {
      this.$nuxt.$emit('show-sign-up-dialog');
    },
    updateTimeline() {
      this.timelineNumDays = Math.ceil(window.innerWidth / dayColumnWidth);
    },
    columnClasses(index) {
      const date = this.date(index);
      if (isWeekend(date)) {
        return { 'is-non-working': true };
      }
    },
    date(index) {
      return this.timelineStartDate.add(index, 'day');
    },
    month(index) {
      const date = this.date(index);
      if (date.date() === 1) {
        return date.format('MMM');
      } else {
        return '';
      }
    },
    dayOfWeek(index) {
      return this.date(index).format('dd').substring(0, 1);
    },
    dayOfMonth(index) {
      return this.date(index).format('D');
    },
    onWindowResize() {
      this.updateTimeline();
    }
  }
}
