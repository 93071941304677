// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sprite-base{background:url(/images/generated/sprite.png) no-repeat;display:block;line-height:0;font-size:0;color:transparent}@media(-webkit-min-device-pixel-ratio:2),(min-device-pixel-ratio:2),(min-resolution:2dppx),(min-resolution:192dpi){.sprite-base{background:url(/images/generated/sprite@2x.png) no-repeat;background-size:147px 1378px}}", ""]);
// Exports
exports.locals = {
	"dayColumnWidth": "40",
	"rowHeight": "51",
	"barSpacing": "15",
	"sectionHeadingHeight": "51",
	"barHeight": "36",
	"computedBarHeight": "11"
};
module.exports = exports;
